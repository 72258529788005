var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('time-basic')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('time-state')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('time-spinbutton')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('time-border-padding')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('time-slot')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('time-internationalization')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }