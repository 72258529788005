var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Time Basic"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("BootstrapVue's custom ")]), _c('code', [_vm._v("<b-time>")]), _c('span', [_vm._v(" component generates a WAI-ARIA compliant time selection widget, which can be used to control other components, or can be used to create customized time picker inputs. ")])]), _c('b-time', {
    attrs: {
      "locale": "en"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }